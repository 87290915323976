import React from 'react'
import { Layout } from '../components/Layout/Layout'
import { H1, P } from '../components/atoms/typo/typo'
import { InLink } from '../components/atoms/ui/links'

const NotFoundPage = (): JSX.Element => (
  <Layout>
    <title>Not Found</title>
    <H1>Page not found</H1>
    <P>
      Sorry{' '}
      <span role="img" aria-label="Pensive emoji">
        😔
      </span>{' '}
      we couldn’t find what you were looking for.
    </P>
    <InLink to="/">Go home</InLink>
  </Layout>
)

export default NotFoundPage
